import Head from 'next/head';
import { supabaseClient } from '@supabase/supabase-auth-helpers/nextjs';
import { useUser } from '@supabase/supabase-auth-helpers/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Loading from 'components/Loading';

export default function HomePage() {
  const { user, isLoading } = useUser();
  const router = useRouter();

  const onSignIn = async () => {
    await supabaseClient.auth.signIn(
      { provider: 'google' },
      { redirectTo: `${process.env.NEXT_PUBLIC_APP_URL}/items` }
    );
  };

  useEffect(() => {
    if (user) {
      router.replace('/items');
    }
  }, [user, router]);

  if (isLoading || user) {
    return <Loading />;
  }

  return (
    <>
      <Head>
        <title>
          Carrier - Read your saved Pocket &amp; Instapaper articles in your
          inbox.
        </title>
      </Head>

      <style jsx>{`
        h3 {
          line-height: 1.5em;
        }
      `}</style>
      <div className="mb-5 pb-4">
        <h3>
          You know those <u>articles</u> that are collecting dust in your
          read-it-later app, <u>Pocket</u>, <u>Instapaper</u>? Well, we will
          pick one at random every day/week/month and <u>email</u> it to you.
        </h3>
        <button onClick={onSignIn} className="btn btn-primary btn-lg mt-4">
          Get Started
        </button>
      </div>

      <hr />

      <ul className="list-unstyled mt-5 pt-3">
        <li className="mb-4">
          <h5>Wait that is it?</h5>
          <p>Yeah, pretty much.</p>
        </li>
        <li className="mb-4">
          <h5>How about privacy?</h5>
          <p>
            We don{`'`}t share your information with anyone. All your sensitive
            data is encrypted and securely stored.
          </p>
        </li>
        <li className="mb-4">
          <h5>Are you going to read my stuff?</h5>
          <p>
            We built this application because we struggled to read our saved
            articles. We don{`'`}t need yours.
          </p>
        </li>
        <li className="mb-4">
          <h5>Why and how is it free?</h5>
          <p>
            Because Carrier is built using various free and open-source tools,
            and so far, it{`'`}s working out fine. The only cost is our labor,
            which we are ok with giving away.
          </p>
        </li>
        <li className="mb-4">
          <h5>I have more questions, can I email you?</h5>
          <p>
            Yep, email us at{' '}
            <a href="mailto:carrier@oneclicklab.com">carrier@oneclicklab.com</a>
          </p>
        </li>
      </ul>

      <div className="text-muted mt-4 mb-2">
        <small>&copy; Copyright {new Date().getFullYear()} OneClick Lab.</small>
      </div>
    </>
  );
}
